.aside-modal-container {
  width: 320px;
  height: 100vh;
  background: white;
  box-shadow: 0 0 10px 5px rgb(208, 208, 208);
}

.admin-pages-wrapper {
  width: calc(100% - 320px);
  height: 100vh;
  overflow: auto;
  padding: 30px 20px 0px 20px;
}

.admin-pages-title {
  text-align: left;
}

.admin-logo-icon {
  width: 100px;
  margin-top: 40px;
}

.admin-page-main-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.admin-page-navigation-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 90%;
  margin: auto;
  margin-top: 60px;
}
.admin-page-navigation-container a {
  color: rgb(67, 67, 67);
  font-weight: bold;
  text-decoration: none;
  margin-bottom: 13px;
  font-size: 18px;
}
.admin-page-navigation-container .active-admin-nav {
  color: rgb(35, 4, 87);
}

.action-buttons {
  margin-top: 60px;
}
.action-buttons .admin-logout-button {
  width: 90%;
  height: 45px;
  border-radius: 12px;
  background: #405FF2;
  border: none;
  color: white;
  font-size: 17px;
  font-weight: bold;
}/*# sourceMappingURL=AsideModal.css.map */