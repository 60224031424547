.pager-history {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.pager-history span {
  margin: 0 3px;
}

.vehicle-details-page-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 42px;
  background: #F6F9FB;
}
.vehicle-details-page-container .vehicle-details-page-content {
  width: 96%;
  max-width: 1396px;
}
.vehicle-details-page-container h1 {
  text-align: left;
  font-family: "HelveticaNeue";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  margin-top: 32px;
}
.vehicle-details-page-container .vehicle-details-lot-number {
  color: var(--Grayscale-700, #4D4D4D);
  text-align: left;
  font-family: "HelveticaNeue";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.vehicle-details-page-container .vehicle-details-columns-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.vehicle-details-page-container .vehicle-details-images-carousel-slider {
  width: 924px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.vehicle-details-page-container .vehicle-details-images-carousel-slider-box {
  width: 100%;
  height: 552px;
  border-radius: 16px;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 32px;
  display: flex;
  align-items: center;
  padding: 0 14px;
  justify-content: space-between;
  position: relative;
}
.vehicle-details-page-container .vehicle-details-carousel-items-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  width: 100%;
  margin-top: 32px;
}
.vehicle-details-page-container .vehicle-details-carousel-items-list .vehicle-details-carousel-list-item {
  width: 204px;
  height: 164px;
  border-radius: 16px;
  flex-shrink: 0;
  margin-right: 18px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 2.5px solid white;
  cursor: pointer;
}
.vehicle-details-page-container .vehicle-details-carousel-items-list .list-active-color-border {
  border: 2.5px solid var(--Primary-900, rgba(90, 126, 255, 0.9));
}
.vehicle-details-page-container .vehicle-details-carousel-items-list-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: 0.7s;
}
.vehicle-details-page-container .slide-switcher-button {
  border: none;
  color: white;
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  width: 60px;
  height: 36px;
}
.vehicle-details-page-container .slide-switcher-button:hover {
  background: var(--Primary-900, rgba(90, 126, 255, 0.9));
  transition: 0.7s;
}
.vehicle-details-page-container .vehicle-details-car-condition-container {
  margin-top: 62px;
}
.vehicle-details-page-container .vehicle-details-car-condition-container .vehicle-details-condition-title {
  color: var(--Grayscale-900, #000);
  font-family: "HelveticaNeue";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 32px;
  text-align: left;
}
.vehicle-details-page-container .vehicle-details-car-condition-container .vehicle-details-condition-box {
  width: 142px;
  height: 134px;
  border-radius: 16px;
  margin-right: 24px;
  background: white;
  border: 1px solid var(--Grayscale-100, #E6E6E6);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.vehicle-details-page-container .vehicle-details-car-condition-container .vehicle-details-condition-box img {
  margin-top: 24px;
}
.vehicle-details-page-container .vehicle-details-car-condition-container .vehicle-details-condition-box span {
  margin-top: 12px;
  color: var(--Grayscale-700, #4D4D4D);
  text-align: center;
  font-family: "HelveticaNeue";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
}
.vehicle-details-page-container .vehicle-details-car-condition-container .vehicle-details-condition-box strong {
  margin-top: 8px;
  color: var(--Grayscale-900, #000);
  text-align: center;
  font-family: "HelveticaNeue";
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}
.vehicle-details-page-container .vehicle-details-car-condition-container .vehicle-details-condition-boxes {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.vehicle-details-page-container .vehicle-details-share-and-save {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.vehicle-details-page-container .vehicle-details-share-and-save span {
  margin-right: 5px;
  color: var(--Grayscale-900, #000);
  font-family: "HelveticaNeue";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.vehicle-details-page-container .share-and-save-buttons {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: white;
  display: flex;
  align-items: center;
  border: none;
  justify-content: center;
}
.vehicle-details-page-container .vehicle-details-save {
  margin-left: 24px;
}
.vehicle-details-page-container .vehicle-details-share, .vehicle-details-page-container .vehicle-details-save {
  display: flex;
  align-items: center;
}

.loading-to-bid {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 11111;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vehicle-details-information-block {
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.vehicle-details-information-block .vehicle-details-bid-section {
  width: 100%;
  padding: 24px;
  height: 277px;
  background: white;
  border-radius: 16px;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.vehicle-details-information-block .vehicle-details-bid-info, .vehicle-details-information-block .vehicle-details-auction-start-date {
  width: 50%;
}
.vehicle-details-information-block .place-pre-bid-button, .vehicle-details-information-block .boy-now-button {
  width: 150px;
  height: 56px;
  border-radius: 16px;
  border: 1px solid rgba(90, 126, 255, 0.9);
  color: rgba(90, 126, 255, 0.9);
  background: rgba(90, 126, 255, 0.9);
}
.vehicle-details-information-block .boy-now-button {
  margin-top: 16px !important;
  background: none;
  width: 100%;
}
.vehicle-details-information-block .prebid-amount-value {
  width: 80px;
  border: none;
  text-align: center;
  outline: none;
}
.vehicle-details-information-block .place-deposit-box {
  margin-top: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.vehicle-details-information-block .prebid-amount-box {
  border-radius: 8px;
  border: 1px solid var(--Grayscale-100, #E6E6E6);
  background: var(--Background-Additional, #FFF);
  display: flex;
  width: 194px;
  height: 52px;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
}
.vehicle-details-information-block .vehicle-details-current-bid-title, .vehicle-details-information-block .vehicle-details-auction-date, .vehicle-details-information-block .vehicle-details-auction-date-title {
  color: var(--Grayscale-700, #4D4D4D);
  font-family: "HelveticaNeue";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.vehicle-details-information-block .vehicle-details-current-bid {
  margin-bottom: 0;
  color: var(--Grayscale-900, #000);
  font-family: "HelveticaNeue";
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px;
}
.vehicle-details-information-block .vehicle-details-current-bid-title {
  margin-bottom: 24px;
}
.vehicle-details-information-block .vehicle-details-auction-date {
  margin-bottom: 0;
}
.vehicle-details-information-block .vehicle-details-auction-date-title {
  margin-bottom: 16px;
}
.vehicle-details-information-block .vehicle-details-auction-information {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.vehicle-details-car-information-block {
  width: 100%;
  background: white;
  border-radius: 16px;
  margin-top: 24px;
  padding: 32px 24px;
}
.vehicle-details-car-information-block p {
  color: var(--Grayscale-900, #000);
  text-align: center;
  font-family: "HelveticaNeue";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0;
}
.vehicle-details-car-information-block .vehicle-details-car-info-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.vehicle-details-car-information-block .vehicle-details-car-info-item img {
  width: 24px;
  margin-right: 8px;
}
.vehicle-details-car-information-block .vehicle-details-car-info-item div {
  display: flex;
  align-items: center;
}
.vehicle-details-car-information-block .vehicle-details-car-info-item-divider {
  background: #E6E6E6;
  height: 1px;
  width: 100%;
  margin: 16px 0;
}

.vehicle-details-consultation-block {
  width: 400px;
  height: 564px;
  padding: 34px 24px 24px 24px;
  background: white;
  border-radius: 16px;
  border: 1px solid var(--Grayscale-100, #E6E6E6);
  margin-top: 24px;
}
.vehicle-details-consultation-block .vehicle-details-consultation-manager {
  display: flex;
  align-items: center;
}
.vehicle-details-consultation-block .phone-field {
  padding-left: 100px !important;
}
.vehicle-details-consultation-block .vehicle-details-consultation-manager-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 80px;
  height: 80px;
  margin-right: 22px;
}
.vehicle-details-consultation-block .vehicle-details-consultation-manager-name {
  color: var(--Grayscale-900, #000);
  leading-trim: both;
  text-edge: cap;
  font-family: "HelveticaNeue";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin: 0;
}
.vehicle-details-consultation-block .vehicle-details-consultation-manager-text {
  color: var(--Grayscale-700, #4D4D4D);
  leading-trim: both;
  text-edge: cap;
  font-family: "HelveticaNeue";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  margin-top: 24px;
  width: 300px;
}
.vehicle-details-consultation-block .vehicle-details-consultation-field {
  display: flex;
  width: 352px;
  height: 56px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 82px;
  flex-shrink: 0;
  border-radius: 16px;
  border: 1px solid var(--Grayscale-100, #E6E6E6);
  margin-bottom: 16px;
  outline: none;
}
.vehicle-details-consultation-block .vehicle-details-consultation-phone-field {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}
.vehicle-details-consultation-block .vehicle-details-consultation-phone-field-language {
  display: flex;
  padding-left: 16px;
  align-items: center;
  position: absolute;
}
.vehicle-details-consultation-block .vehicle-details-consultation-phone-field-language img {
  margin-right: 8px;
}
.vehicle-details-consultation-block .vehicle-details-consultation-phone-field-language p {
  margin-bottom: 0;
}
.vehicle-details-consultation-block .send-car-request-button {
  display: flex;
  width: 352px;
  height: 56px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 82px;
  flex-shrink: 0;
  border-radius: 16px;
  border: 1px solid var(--Primary-900, rgba(90, 126, 255, 0.9));
  color: var(--Primary-900, rgba(90, 126, 255, 0.9));
  leading-trim: both;
  text-edge: cap;
  font-family: "HelveticaNeue";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  background: none;
  line-height: 26px;
  margin-top: 40px;
}

.vehicle-details-hr {
  background: var(--Grayscale-100, #E6E6E6);
  height: 1px;
  width: 100%;
  margin: 32px 0;
}

.vehicle-details-description-block {
  width: 100%;
  padding: 32px 32px 40px 32px;
  background: white;
  border-radius: 16px;
  box-shadow: 0px 1px 0px 0px var(--Alpha-50, rgba(0, 0, 0, 0.04)), 0px 1px 4px 0px var(--Alpha-100, rgba(0, 0, 0, 0.08));
}
.vehicle-details-description-block p {
  margin: 0;
  color: var(--Grayscale-900, #000);
  leading-trim: both;
  text-edge: cap;
  font-family: "HelveticaNeue";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  align-self: stretch;
  text-align: left;
}

.auction-calculator-container {
  width: 100%;
  padding: 32px;
  border-radius: 16px;
  box-shadow: 0px 1px 0px 0px var(--Alpha-50, rgba(0, 0, 0, 0.04)), 0px 1px 4px 0px var(--Alpha-100, rgba(0, 0, 0, 0.08));
  background: white;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 32px;
}
.auction-calculator-container .auction-calculator-container-banner {
  width: 432px;
  height: 683px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.auction-calculator-container .auction-fee-calculator-title {
  color: var(--Grayscale-900, #000);
  leading-trim: both;
  text-edge: cap;
  font-family: "HelveticaNeue";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  margin-bottom: 24px;
}
.auction-calculator-container .auction-calculator-form {
  width: 412px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 683px;
}
.auction-calculator-container .auction-winning-amount-field {
  width: 256px;
  height: 64px;
  border-radius: 16px;
  border: 1px solid var(--Grayscale-100, #E6E6E6);
  outline: none;
  padding-left: 16px;
}
.auction-calculator-container .auction-fee-calculate-button {
  display: flex;
  width: 142px;
  height: 64px;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 32px;
  border-radius: 16px;
  background: var(--Gradient-Main-Main, linear-gradient(254deg, #75D4FD -141.51%, #5A7EFF 108.14%));
  border: none;
  color: white;
  font-size: 12px;
}
.auction-calculator-container .auction-winning-amount-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}
.auction-calculator-container .auction-fee-form-item {
  width: 100%;
  display: flex;
  height: 64px;
  padding: 16px;
  align-items: center;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid rgba(230, 230, 230, 0.2);
  background: var(--Shadow-XS, #FFF);
  margin-bottom: 16px;
}
.auction-calculator-container .auction-fee-form-item-image {
  width: 44px;
  height: 44px;
  border-radius: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.auction-calculator-container .auction-fee-form-item-detail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 8px;
}
.auction-calculator-container .auction-fee-form-item-detail p, .auction-calculator-container .auction-fee-form-item-detail span {
  margin: 0;
  padding: 0;
}
.auction-calculator-container .auction-fee-form-item-detail p {
  color: var(--Grayscale-300, #B3B3B3);
  font-family: "HelveticaNeue";
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 160% */
}
.auction-calculator-container .auction-fee-form-item-detail span {
  color: var(--Grayscale-900, #000);
  font-family: "HelveticaNeue";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.28px;
}

.fee-form-divider-line {
  background: rgba(230, 230, 230, 0.6);
  height: 1px;
  width: 100%;
  margin-bottom: 12px;
}

.auction-fee-form-sum-value {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.auction-fee-form-sum-value {
  color: var(--Grayscale-900, #000);
  font-family: "HelveticaNeue";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.auction-fee-form-sum-count-value {
  color: var(--System-informative-600, #2D9CCF);
  font-family: "HelveticaNeue";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media (max-width: 1400px) {
  .vehicle-details-page-container .vehicle-details-images-carousel-slider {
    width: calc(100% - 410px);
  }
}
@media (max-width: 1024px) {
  .vehicle-details-page-container .vehicle-details-images-carousel-slider {
    width: 100%;
  }
  .vehicle-details-page-container .vehicle-details-columns-container {
    flex-direction: column;
  }
  .vehicle-details-page-container .vehicle-details-information-block {
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .auction-calculator-container {
    flex-direction: column;
  }
  .auction-calculator-container .auction-calculator-container-banner {
    width: 100%;
    margin-bottom: 25px;
    height: 480px;
    border-radius: 16px;
  }
  .auction-calculator-container .auction-calculator-form {
    width: 100%;
  }
  .vehicle-details-consultation-block {
    width: 100%;
    margin-bottom: 40px;
  }
  .vehicle-details-consultation-block input, .vehicle-details-consultation-block button, .vehicle-details-consultation-block p {
    width: 100% !important;
  }
  .vehicle-details-consultation-block .vehicle-details-consultation-manager-name {
    width: -moz-fit-content !important;
    width: fit-content !important;
  }
  .vehicle-details-auction-information {
    width: 100%;
  }
  .vehicle-details-information-block .place-deposit-box {
    margin-top: 25px;
  }
  .vehicle-details-information-block .vehicle-details-bid-section {
    height: unset;
  }
  .vehicle-details-car-condition-container {
    width: 100%;
  }
  .vehicle-details-condition-boxes {
    width: 100%;
    overflow: auto;
  }
  .vehicle-details-condition-boxes::-webkit-scrollbar {
    display: none;
  }
  .vehicle-details-condition-box {
    min-width: 150px;
  }
  .lead-form-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .lead-form-submit-button {
    margin-bottom: 25px;
  }
  .lead-form-title {
    margin-top: 50px !important;
  }
  .about-us-container .why-choose-us-item {
    min-width: 200px;
  }
}
@media (max-width: 550px) {
  .vehicle-details-images-carousel-slider-box {
    height: 330px !important;
  }
  .vehicle-details-page-container h1 {
    font-size: 16px;
  }
}/*# sourceMappingURL=VehicleDetails.css.map */