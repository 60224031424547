.catalogue-page-filter-container{
    padding: 32px 0;
    display: flex;
    justify-content: center;
    z-index: -1;
}
.catalogue-filter-row{
    width: 96%;
    max-width: 1396px;
    border-radius: 16px;
    border: 1px solid var(--Grayscale-200, #CCC);
    background: var(--Background-Main, #F6F9FB);
    padding: 12px 24px;
    display: flex;
    justify-content: space-between;
}
.target-box{
    background: white;
    width: 12px;
    height: 12px;
    position: absolute;
    margin-top: 28px;
    transform: rotate(45deg);
    margin-left: 100%;

}
.top-brands-item{
    display: flex;
    width: 36px;
    height: 36px;
    padding: 12px;
    align-items: center;
    gap: 10px;
    border-radius: 18px;
    border: 1px solid var(--Grayscale-100, #E6E6E6);
    background: #FFF;
    box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.05);
    justify-content: center;
    cursor: pointer;
}
.filters-items-list{
    display: flex;
    align-items: center;
}
.top-brands-list{
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}
.top-brands-title{
    color: var(--Grayscale-900, #000);
    text-align: left;
    padding-left: 16px;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}
.catalogue-filter-row{
    display: flex;
    align-items: center;
}
.dropdown-filter-input{
    width: calc(100% - 32px);
    margin: 20px auto 20px auto;
    height: 42px;
    padding-left: 16px;
    border: 1px solid rgb(189, 189, 189);
    border-radius: 16px;
    outline: none;
}
.catalogue-page-filter-container{
    .form-control-select{
        width: fit-content;
        height: 38px;
        border-radius: 8px;
        font-size: 17px;
        outline: none;
        position: relative;
    }
    .form-control-select-box{
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;
        p{
            margin: 0;
            padding: 0;
            width: 100%;
            color: var(--Secondary-900, rgba(45, 45, 45, 0.90));
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
        }
    }
    .select-dropdown-icon{
        width: 10px;
        margin-left: 28px;
    }
    .form-control-dropdown-item{
        padding: 10px 0 10px 16px;
        cursor: pointer;
        &:hover{
            background: #5B7EFE;
            color: white;
            transition: 0.5s;
        }
        p{
            margin: 0;
        }
        display: flex;
        align-items: center;
        justify-content: flex-start;
        label{
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            margin-left: 8px;
        }
    }
    .disabled-dropdown{
        cursor: no-drop;
        p{
            color: #757575;
        }
    }
    .additional-filter-button{
        display: flex;
        align-items: center;
        cursor: pointer;
        span{
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            color: var(--Secondary-900, rgba(45, 45, 45, 0.90));
            margin-left: 14px;
        }
    }
    .form-control-select-dropdown{
        width: 100%;
        overflow: auto;
        height: fit-content;
        z-index: 111111;
        max-height: 400px;
        position: absolute;
        background: white;
        margin-top: 3px;
        border-radius: 16px;
        background: var(--Shadow-XS, #FFF);
        margin-top: 34px;
        /* Shadow/XS */
        box-shadow: 0px 1px 0px 0px var(--Alpha-50, rgba(0, 0, 0, 0.04)), 0px 1px 4px 0px var(--Alpha-100, rgba(0, 0, 0, 0.08));
        // &::-webkit-scrollbar-button{
        //     width: 5px;
        //     background: gray;
        //     border-radius: 2px;
        // }
        // &::-webkit-scrollbar-corner{
        //     width: 5px;
        //     background: gray;
        //     border-radius: 2px;
        // }
        // &::-webkit-scrollbar-track{
        //     width: 5px;
        //     background: gray;
        //     border-radius: 2px;
        // }
        // &::-webkit-scrollbar-track-piece{
        //     width: 5px;
        //     background: gray;
        //     border-radius: 2px;
        // }
        &::-webkit-scrollbar {
            width: 10px;
        }
        &::-webkit-scrollbar-track {
            background: #f1f1f1; 
        }
        &::-webkit-scrollbar-thumb {
            background: #888; 
              border-radius:10px;
        }
        &::-webkit-scrollbar-thumb:hover {    
            background: #555; 
        } 
    }
    .mark-selector-dropdown{
        width: 380px;
        padding: 24px 0;
    }
    .millage-selector{
        width: 384px;
        height: 314px;
        padding: 24px 36px 34px 36px;
        .millage-dropdown-title{
            text-align: left;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
        }
        .millage-input-range-content{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .millage-input-box{
            border-radius: 12px;
            border: 1px solid var(--Grayscale-200, #CCC);
            background: var(--Base-White-50, rgba(255, 255, 255, 0.05));
            width: 138px;
            height: 53px;
            padding: 8px 16px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            span{
                font-size: 13px;
            }
            .no-border{
                width: 106px;
                padding: 0;
                height: 18px;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                border: none;
                outline: none;
            }
        }
        .range-slider-two-switcher{
            margin-top: 24px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            img{
                z-index: 1111;
                position: relative;
                cursor: pointer;
            }
        }
        .save-millage-button{
            background: var(--Gradient-Main-Main, linear-gradient(254deg, #75D4FD -141.51%, #5A7EFF 108.14%));
            border: 1px solid var(--Gradient-Main-Main, linear-gradient(254deg, #75D4FD -141.51%, #5A7EFF 108.14%));
            color: white;
            border-radius: 16px;
            padding: 10px 15px;
            margin-top: 20px;
            width: 100%;
            height: 42px;
        }
        .range-slider-line-empty{
            border-radius: 30px;
            background: var(--Grayscale-100, #E6E6E6);
            width: 309px;
            height: 3px;
            position: absolute;
            cursor: pointer;
        }
    }
    .years-selector{
        height: 272px;
    }
    .filter-search-button{
        display: flex;
        width: 154px;
        height: 56px;
        padding: 16px 32px;
        justify-content: center;
        align-items: center;
        border-radius: 16px;
        background: var(--Gradient-Main-Main, linear-gradient(254deg, #75D4FD -141.51%, #5A7EFF 108.14%));
        border: none;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        color: var(--Base-white-900, rgba(255, 255, 255, 0.90));
    }
}
.filter-divider{
    width: 1px;
    height: 36px;
    background: #E6E6E6;
    margin: 0 24px 0 42px;
}

.range-slider-two-switcher{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .range-selector-dropdown-column{
        margin-bottom: 0;
        overflow: auto;
        width: 138px;
        max-height: 75px;
        padding-left: 0;
        &::-webkit-scrollbar{
            display: none;
        }
        li{
            width: 100%;
            font-size: 14px;
            height: 17px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            cursor: pointer;
            &:hover{
                padding: 6px 0;
                border-radius: 8px;
                height: 23px;
                background: var(--Grayscale-100, #E6E6E6);
            }
        }
        .active{
            padding: 6px 0;
            border-radius: 8px;
            height: 23px;
            background: var(--Grayscale-100, #E6E6E6);
        }
    }
}

.miles-to-km-switcher{
    display: flex;
    justify-content: flex-end;
}

.form-control-select{
    .switch {
        position: relative;
        display: inline-block;
        width: 44px;
        height: 34px;
      }
      
      .switch input { 
        opacity: 0;
        width: 0;
        height: 0;
      }
      
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        width: 44px;
        height: 24px;
        bottom: 0;
        background-color: #2196F3;
        -webkit-transition: .4s;
        transition: .4s;
      }
      
      .slider:before {
        position: absolute;
        content: "";
        height: 17px;
        width: 17px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
      }
      
      input:checked + .slider {
        background-color: #2196F3;
      }
      
      input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
      }
      
      input:checked + .slider:before {
        -webkit-transform: translateX(18px);
        -ms-transform: translateX(18px);
        transform: translateX(18px);
      }
      
      /* Rounded sliders */
      .slider.round {
        border-radius: 34px;
      }
      
      .slider.round:before {
        border-radius: 50%;
      }
}

.detailed-search-container{
    width: 100%;
    height: 302px;
    max-width: 1192px;
    background: white;
    border-radius: 16px;
    position: absolute;
    margin-top: 92px;
    padding: 32px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    z-index: 111;
    .form-control-select-box {
        width: 216px;
        height: 56px;
        border-radius: 12px;
        border: 1px solid var(--Grayscale-200, #CCC);
        padding: 16px;
        p{
            text-align: left;
        }
    }
    .form-control-select-dropdown{
        margin-top: 8px;
    }
}

.dropdown-checkbox{
    width: 20px;
    height: 20px;
    background: green;
    border-radius: 5px;
    margin-right: 8px;
}

.list-with-checkbox{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 12px;
    cursor: pointer;
    margin-bottom: 13px;
}

.search-by-vin-lot{
    display: flex;
    width: 444px;
    height: 56px;
    padding: 16px 16px 16px 52px;
    justify-content: center;
    align-items: center;
    gap: 82px;
    flex-shrink: 0;
    border-radius: 12px;
    border: 1px solid var(--Grayscale-200, #CCC);
    outline: none;
}

.catalogue-filter-item-box{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.catalogue-filter-loop{
    position: absolute;
    margin-left: 16px;
    cursor: pointer;
}

@media(max-width: 1024px){
    .catalogue-filter-row, .filters-items-list{
        flex-direction: column;
        width: 100%;
    }
    .form-control-select{
        width: 100% !important;
    }
    .catalogue-filter-row{
        border: none !important;
        border: 1px solid var(--Grayscale-200, #CCC);
    }
    .catalogue-filter-item-box{
        width: 100%;
    }
    .form-control-select-box p{
        text-align: left;
    }
    .catalogue-filter-item-box{
        border-radius: 8px;
        border: 1px solid var(--Grayscale-100, #E6E6E6);
    }
}