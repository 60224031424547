.about-us-page-map {
  margin-top: 42px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.about-us-page-map span {
  margin: 0 5px;
}
.about-us-page-map a, .about-us-page-map span {
  color: var(--Grayscale-600, #666);
  text-align: center;
  font-family: "HelveticaNeue";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-transform: uppercase;
  text-decoration: none;
}

.page-not-found-container {
  width: 96%;
  max-width: 1396px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.page-not-found-container .page-not-found-image {
  width: 640px;
  margin-bottom: 24px;
}
.page-not-found-container h3 {
  color: var(--Grayscale-900, #000);
  text-align: center;
  font-family: "HelveticaNeue";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 45px;
}
.page-not-found-container p {
  color: var(--Grayscale-900, #000);
  text-align: center;
  font-family: "HelveticaNeue";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 54px;
  max-width: 936px;
  margin-bottom: 32px;
}
.page-not-found-container a {
  display: inline-flex;
  height: 82px;
  padding: 0 32px;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 16px;
  background: #405FF2;
  color: var(--Base-white-900, rgba(255, 255, 255, 0.9));
  text-align: center;
  font-family: "HelveticaNeue";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 80px;
}

.contact-page-container {
  width: 96%;
  max-width: 1396px;
  margin: auto;
}

.contact-page .section-title {
  text-align: left;
}
.contact-page .form-control, .contact-page .form-control-textarea, .contact-page .main-page-contact-info {
  background: white;
}
.contact-page .main-page-contacts {
  margin-top: 32px;
}

.transportation-calculator-page {
  width: 96%;
  max-width: 1396px;
  margin: auto;
}

.contact-page-title {
  text-align: left;
  margin-bottom: 64px;
  margin-top: 32px;
}

.about-us-container {
  width: 96%;
  max-width: 1396px;
  margin: auto;
  margin-top: 242px;
}
.about-us-container .about-us-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.about-us-container .about-us-content .about-us-description-text {
  width: 100%;
}
.about-us-container .why-choose-us-item {
  width: 416px;
  padding: 24px;
}
.about-us-container .why-choose-us-item-description {
  font-size: 12px;
}
.about-us-container .why-choose-us-item-title {
  font-size: 16px;
  margin: 16px 0 24px 0;
}
.about-us-container .about-us-title {
  color: var(--Grayscale-900, #000);
  leading-trim: both;
  text-edge: cap;
  font-family: "HelveticaNeue";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 45px;
  text-align: left;
  margin-top: 32px;
  margin-bottom: 50px;
}
.about-us-container .about-us-page-details {
  color: #BABABA;
  leading-trim: both;
  text-align: left;
  text-edge: cap;
  font-family: "HelveticaNeue";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.about-us-container .about-us-subtitle {
  color: var(--Grayscale-800, #333);
  leading-trim: both;
  text-edge: cap;
  font-family: "HelveticaNeue";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 45px;
  text-align: left;
}
.about-us-container .about-us-page-hr {
  background: #E1E1E1;
  height: 1px;
  width: 100%;
  margin: 32px 0 20px 0;
}
.about-us-container .about-us-content-lists {
  text-align: left;
  list-style-type: disc;
}
.about-us-container .about-us-content-lists li {
  color: var(--Grayscale-800, #333);
  leading-trim: both;
  text-edge: cap;
  font-family: "HelveticaNeue";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.about-us-banner {
  width: 100%;
  height: 642px;
  flex-shrink: 0;
  background-image: url("https://carspace-app.crg.ge/static/about-us-banner.png");
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.about-us-banner .about-us-banner-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: rgba(5, 11, 32, 0.88);
}
.about-us-banner p {
  max-width: 1424px;
  color: #BABABA;
  text-align: center;
}
.about-us-banner h3 {
  color: white;
  margin-bottom: 34px;
}

.about-us-page-banners-container {
  margin-top: 145px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}
.about-us-page-banners-container div {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  text-align: left;
  border-radius: 16px;
}
.about-us-page-banners-container .banner-with-two-col {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.banner-first-col {
  width: 15%;
  min-width: 192px;
}

.banner-second-col {
  width: 40%;
  min-width: 464px;
}

.banner-third-col {
  width: 40%;
  min-width: 192px;
}

.services-counter-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 128px;
  margin-bottom: 10px;
}

.service-count-value {
  color: var(--Grayscale-900, #000);
  text-align: center;
  font-family: "HelveticaNeue";
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.service-count-label {
  color: #050B20;
  text-align: center;
  font-family: "HelveticaNeue";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.about-us-page-hr {
  background: #E1E1E1;
  height: 1px;
  width: 100%;
  margin-bottom: 71px;
}

.lead-form-details-text {
  color: #050B20;
  margin-left: 12px;
  text-decoration: none;
}

.lead-form-details-box {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
}

.lead-form-container {
  width: 90%;
  max-width: 1396px;
  margin: auto;
  padding: 54px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.lead-form-container .lead-form-col {
  width: 562px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.lead-form-container .form-label {
  position: absolute;
  color: var(--Grayscale-400, #999);
  font-family: "HelveticaNeue";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding-top: 24px;
  padding-left: 23px;
  transition: 0.4s;
  z-index: 0;
}
.lead-form-container .form-label-with-icon {
  position: absolute;
  color: var(--Grayscale-300, #B3B3B3);
  font-family: "HelveticaNeue";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding-top: 24px;
  padding-left: 49px;
  transition: 0.4s;
  z-index: 0;
}
.lead-form-container label {
  color: #586279;
  font-size: 16px;
  margin-bottom: 14px;
}
.lead-form-container .form-control {
  height: 52px;
  background: none;
  border-radius: 12px;
  border: 1px solid var(--Grayscale-100, #E6E6E6);
  gap: 4px;
  z-index: 1;
}
.lead-form-container .label-blured {
  padding-top: 8px;
  padding-left: 16px;
  z-index: 11;
}
.lead-form-container .form-field-divided {
  width: calc(50% - 14px);
  max-width: 524px;
}
.lead-form-container .form-group {
  margin-bottom: 32px;
  position: relative;
}
.lead-form-container .form-control-textarea {
  width: 100%;
  height: 258px;
  border-radius: 12px;
  border: 1px solid var(--Grayscale-100, #E6E6E6);
  outline: none;
  text-align: left;
  padding: 16px 16px 16px 16px;
  color: var(--Grayscale-900, #000);
  font-family: "HelveticaNeue";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 111.111% */
  letter-spacing: 0.36px;
}
.lead-form-container .send-message-text {
  width: 100%;
  height: 182px;
  padding: 16px 15px;
  border: #E6E6E6 1px solid;
  border-radius: 12px;
  outline: none;
  margin-bottom: 42px;
  background: none !important;
}
.lead-form-container .comment-head-title {
  z-index: 1;
  color: #586279;
  margin-bottom: 14px;
}

.lead-form-submit-button {
  background: rgb(64, 95, 242);
  border: none;
  color: white;
  border-radius: 12px;
  width: 100%;
  height: 64px;
}

.lead-form-banner {
  width: 424px;
  height: 222px;
  margin-bottom: 42px;
}

.lead-form-title {
  width: 100%;
  margin-top: 180px;
  text-align: left;
  margin-bottom: 42px;
  font-weight: 500;
}/*# sourceMappingURL=aboutus.css.map */