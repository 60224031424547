.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body{
  background: #f6f9fb !important;
}

@font-face {
  font-family: "HelveticaNeue";
  src: url("../public/fonts/HelveticaNeueLight.otf")
}

@font-face {
  font-family: "Poppins";
  src: url("../public/fonts/Poppins-Regular.ttf")
}

.main-page-container{
  display: flex;
  align-items: center;
  flex-direction: column;
}

.main-page-content{
  width: 90%;
  max-width: 1396px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font-family: HelveticaNeue;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
.d-flex{
  display: flex;
}
.flex-center-start{
  align-items: center;
  justify-content: flex-start;
}
.flex-center-enn{
  align-items: center;
  justify-content: flex-end;
}
.scroll-to-top{
  position: fixed;
  bottom: 96px;
  right: 52px;
  border-radius: 28px;
  width: 56px;
  height: 56px;
  border: none;
  z-index: 11111;
  background: var(--Gradient-Main-Hover, linear-gradient(81deg, #75D4FD -24.85%, #5A7EFF 82.35%));
}

.dealers-page-container{
  width: 90%;
  max-width: 1396px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.disabled-prebid-btn{
  background: rgb(210, 210, 210) !important;
  border: rgb(210, 210, 210) 1px solid !important;
}

.disabled-buynow-btn{
  color: rgb(210, 210, 210) !important;
  border: rgb(210, 210, 210) 1px solid !important;
}

.dealers-page-container .pager-history{
  display: flex;
  align-items: center;
  margin-top: 42px;
}

.watched-button{
  background: #5A7EFF !important;
}

.dealers-page-container .pager-history a{
  text-decoration: none;
  font-size: 14px;
  color: #666;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
}

.dealer-registration-form{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 708px;
  border-radius: 16px;
  background: #050B20;
  margin-bottom: 200px;
}