.create-blog-container {
  width: 96%;
  max-width: 1396px;
  margin: auto;
}
.create-blog-container .blogs-form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 30px;
  width: 100%;
}
.create-blog-container .blogs-form-control {
  width: 100%;
  height: 50px;
  border: 1px solid gray;
  border-radius: 10px;
  background: none;
  margin-top: 5px;
  padding-left: 15px;
  font-size: 17px;
  outline: none;
}
.create-blog-container .save-blog-button {
  width: 100%;
  height: 55px;
  background: #5A7EFF;
  border: none;
  border-radius: 16px;
  color: white;
  margin-bottom: 30px;
}/*# sourceMappingURL=CreateBlog.css.map */