.personal-pages-container {
  width: 96%;
  max-width: 1396px;
  display: flex;
  margin: auto;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 180px;
}
.personal-pages-container .personal-pages-title {
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  width: 100%;
  text-align: left;
  margin: 94px 0 64px 0;
}
.personal-pages-container .personal-page-page-switcher-box {
  width: 340px;
  height: 578px;
  background: white;
  box-shadow: 0px 1px 0px 0px var(--Alpha-50, rgba(0, 0, 0, 0.04)), 0px 1px 4px 0px var(--Alpha-100, rgba(0, 0, 0, 0.08));
  border-radius: 20px;
  padding: 42px 32px;
}
.personal-pages-container .personal-page-page-switcher-box a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  margin-bottom: 24px;
}
.personal-pages-container .personal-page-page-switcher-box a div {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 26px;
  background: rgba(230, 230, 230, 0.4);
}
.personal-pages-container .personal-page-page-switcher-box p {
  margin: 0 0 0 12px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.personal-pages-container .personal-details-profile-img-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(230, 230, 230, 0.5);
  margin-bottom: 32px;
}
.personal-pages-container .personal-details-profile-img {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: black;
  margin-right: 12px;
}
.personal-pages-container .personal-details-profile-fullname-phone {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.personal-pages-container .personal-details-profile-fullname-phone p {
  margin: 0;
  padding: 0;
  text-align: left;
}
.personal-pages-container .personal-page-menu-divider {
  width: 100%;
  background: rgba(230, 230, 230, 0.5);
  height: 1px;
  margin: 8px 0 16px 0;
}
.personal-pages-container .personal-page-menu-logout {
  display: flex;
  align-items: center;
  width: 100%;
  background: none;
  border: none;
  padding: 0;
}
.personal-pages-container .personal-page-menu-logout div {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--Background-System-Negative, rgba(229, 62, 51, 0.1));
  border-radius: 50%;
  margin-right: 12px;
}
.personal-pages-container .personal-page-menu-logout span {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.personal-pages-content-box {
  width: calc(100% - 376px);
  box-shadow: 0px 1px 0px 0px var(--Alpha-50, rgba(0, 0, 0, 0.04)), 0px 1px 4px 0px var(--Alpha-100, rgba(0, 0, 0, 0.08));
  border-radius: 20px;
  background: white;
}

.personal-details-page-container {
  width: 100%;
  padding: 62px 32px;
}

.persinal-info-form {
  padding: 42px 110px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.persinal-info-form .form-group {
  width: calc(50% - 22px);
  margin-bottom: 24px;
}

.save-personal-details {
  border-radius: 8px;
  background: var(--Gradient-Main-Main, linear-gradient(254deg, #75D4FD -141.51%, #5A7EFF 108.14%));
  border: none;
  width: 346px;
  height: 64px;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 32px;
  color: white;
}

.change-password-button {
  width: 346px;
  height: 64px;
  padding: 16px 32px;
  border-radius: 8px;
  background: var(--Gradient-Main-Main, linear-gradient(254deg, #75D4FD -141.51%, #5A7EFF 108.14%));
  border: none;
  color: white;
}

.no-background {
  background: none !important;
  box-shadow: unset !important;
  border: none !important;
}

.switcher-tabs-header {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid rgba(230, 230, 230, 0.5);
  width: 100%;
  padding-bottom: 12px;
}
.switcher-tabs-header span {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color: #999;
  cursor: pointer;
  margin: 0 52px;
}
.switcher-tabs-header .active-tab {
  color: black;
}

.won-vehicles-page {
  padding: 56px 42px;
}

.won-vehicles-search-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.search-won-vehicle-button {
  border-radius: 8px;
  background: var(--Primary-800, rgba(90, 126, 255, 0.8));
  width: 32px;
  height: 32px;
  border: none;
  position: absolute;
  margin-left: 16px;
}

.search-won-vehicle-input {
  width: 100%;
  height: 52px;
  border-radius: 8px;
  background: #FFF;
  outline: none;
  border: none;
  padding-left: 60px;
  box-shadow: 0px 1px 0px 0px var(--Alpha-50, rgba(0, 0, 0, 0.04)), 0px 1px 4px 0px var(--Alpha-100, rgba(0, 0, 0, 0.08));
}

.won-vehicle-filter-button {
  border-radius: 6px;
  background: rgba(225, 225, 225, 0.3);
  padding: 16px;
  font-size: 10px;
  color: #808080;
  border: none;
  margin-right: 12px;
}

.won-vehicles-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.won-vehicles-filter-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 24px;
}
.won-vehicles-filter-buttons .active {
  border-radius: 6px;
  background: var(--Background-Primary, rgba(90, 126, 255, 0.1));
  color: var(--Primary-800, rgba(90, 126, 255, 0.8));
}

.sort-vehicles-button {
  border-radius: 6px;
  border: 1px solid var(--Primary-800, rgba(90, 126, 255, 0.8));
  background: #FFF;
  width: 162px;
  height: 42px;
  color: var(--Primary-900, rgba(90, 126, 255, 0.9));
  font-size: 12px;
  justify-content: center;
  align-items: center;
}
.sort-vehicles-button span {
  margin-left: 16px;
}

.table-head-divider {
  height: 1px;
  width: 100%;
  background: rgba(230, 230, 230, 0.5);
}

.won-vehicles-table-header {
  width: 100%;
}
.won-vehicles-table-header th {
  color: var(--Grayscale-600, #666);
  text-align: center;
  font-family: "Helvetica Neue";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  padding-bottom: 12px;
}

.won-vehicles-table {
  width: 100%;
  margin-top: 56px;
}
.won-vehicles-table tr td {
  text-align: left;
  vertical-align: top;
  padding-bottom: 20px;
}
.won-vehicles-table tr td p {
  font-size: 12px;
  margin: 0;
}
.won-vehicles-table tr td .td-second-row {
  color: var(--Grayscale-900, #808080);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 8px;
}
.won-vehicles-table .won-vehicles-table-divider {
  border-top: rgba(230, 230, 230, 0.5) 1px solid;
  padding: 12px 0;
}

.pt-28 {
  padding-top: 28px;
}

.vehicle-img-row {
  width: 168px;
}

.vehicle-list-table-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 148px;
  height: 132px;
  border-radius: 16px;
  margin-right: 20px;
}

.won-vehicle-min-height {
  min-height: 578px;
}

.saved-cars-page {
  padding: 0 !important;
}

.no-won-item {
  height: 343px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.no-won-item p {
  margin-top: 32px;
  margin-bottom: 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.change-active-currency {
  width: 52px;
  height: 52px;
  border-radius: 8px;
  background: #405FF2;
  font-size: 20px;
  color: white;
  border: none;
}

.top-up-balance-header {
  display: flex;
  align-items: center;
}
.top-up-balance-header .current-balance-amount {
  margin-left: 24px;
}
.top-up-balance-header .current-balance-amount-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--Grayscale-600, #666);
  margin-bottom: 8px;
  text-align: left;
}
.top-up-balance-header .current-balance-amount-value {
  color: var(--Grayscale-900, #000);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  margin-bottom: 0;
}

.pay-amount-block {
  margin-left: 106px;
}
.pay-amount-block .form-label {
  position: absolute;
  color: var(--Grayscale-300, #B3B3B3);
  font-family: "HelveticaNeue";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding-top: 19px;
  padding-left: 23px;
  transition: 0.4s;
  z-index: 1;
}
.pay-amount-block .form-control {
  padding: 16px;
  padding-top: 28px;
  height: 52px;
  background: none;
  border-radius: 12px;
  border: 1px solid var(--Grayscale-100, #E6E6E6);
  gap: 4px;
  width: 166px;
}
.pay-amount-block .label-blured {
  padding-top: 4px;
  padding-left: 16px;
}
.pay-amount-block .form-field-divided {
  width: calc(50% - 21px) !important;
}
.pay-amount-block .form-control-textarea {
  width: 100%;
  height: 258px;
  border-radius: 12px;
  border: 1px solid var(--Grayscale-100, #E6E6E6);
  outline: none;
  text-align: left;
  padding: 25px 16px 16px 16px;
  color: var(--Grayscale-900, #000);
  font-family: "HelveticaNeue";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 111.111% */
  letter-spacing: 0.36px;
}

.top-up-page-divider {
  background: rgba(230, 230, 230, 0.5);
  height: 1px;
  width: 100%;
  margin: 40px 0;
}

.cart-list-title {
  color: var(--Grayscale-600, #666);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  margin-bottom: 24px;
}

.top-up-balance-button {
  border-radius: 8px;
  background: var(--Gradient-Main-Main, linear-gradient(254deg, #75D4FD -141.51%, #5A7EFF 108.14%));
  width: 142px;
  height: 52px;
  color: white;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  border: none;
  margin-left: 24px;
}

.bank-cards-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.bog-card {
  background: linear-gradient(95deg, #FE5F0A 0%, #FE9B67 130.83%);
}

.tbc-card {
  background: linear-gradient(95deg, #32ADE6 0%, #77C8EE 133.15%);
}

.bank-card-box {
  width: 342px;
  height: 134px;
  border-radius: 16px;
  margin-right: 24px;
  padding: 22px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.bank-card-box p {
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin: 0;
}
.bank-card-box .bank-card-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-new-card-button {
  border-radius: 32px;
  background: var(--Base-white-900, rgba(255, 255, 255, 0.9));
  box-shadow: 0px 1px 0px 0px var(--Alpha-50, rgba(0, 0, 0, 0.04)), 0px 1px 4px 0px var(--Alpha-100, rgba(0, 0, 0, 0.08));
  width: 62px;
  height: 62px;
  border-radius: 50%;
  border: none;
  margin-left: 18px;
}

.transaction-history-container {
  padding-top: 52px;
  padding-bottom: 52px;
}

/* HTML: <div class="loader"></div> */
.content-loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #ffa516 94%, rgba(0, 0, 0, 0)) top/8px 8px no-repeat, conic-gradient(rgba(0, 0, 0, 0) 30%, #ffa516);
  -webkit-mask: radial-gradient(farthest-side, rgba(0, 0, 0, 0) calc(100% - 8px), #000 0);
  animation: l13 1s infinite linear;
}

@keyframes l13 {
  100% {
    transform: rotate(1turn);
  }
}/*# sourceMappingURL=LoggedInUsers.css.map */