.catalogue-page-container {
  background: #F6F9FB;
}
.catalogue-page-container .Mui-selected {
  background-color: black !important;
  color: white !important;
}

.page-title {
  text-align: left;
  color: #666;
  font-size: 14px;
  padding-top: 40px;
  width: 96%;
  max-width: 1396px;
  display: block;
  margin: auto;
}

.vehicle-card-thumbnail {
  border-radius: 16px;
  width: 264px;
  height: 199px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.catalogue-pagination-container {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}
.catalogue-pagination-container .MuiPaginationItem-previousNext {
  width: 56px;
  height: 40px;
  border-radius: 30px;
  border: 1px solid var(--Grayscale-800, #333);
}

.vehicle-card-hr {
  color: #E6E6E6;
  margin: 33px 0 16px 0;
  width: 100%;
}

.vehicle-card-auction-date, .vehicle-card-viewers {
  display: flex;
  align-items: center;
}
.vehicle-card-auction-date span, .vehicle-card-viewers span {
  margin-left: 8px;
  color: var(--Grayscale-500, #808080);
  font-family: "HelveticaNeue";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}

.vehicle-card-auction-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.cars-items-card-box-cont {
  width: calc(25% - 14px);
  position: relative;
  margin-right: 28px;
  display: flex;
  justify-content: flex-end;
}

.cars-items-card-box {
  display: flex;
  align-items: center;
  margin-bottom: 60px;
  width: 100%;
  background: white;
  padding: 34px 32px;
  border-radius: 16px;
  flex-direction: column;
}
.cars-items-card-box .vehicle-card-details-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 16px;
  align-items: flex-start;
}
.cars-items-card-box .vehicle-card-details-box .vehicle-card-car-name {
  color: var(--Color-Primary, #050B20);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.cars-items-card-box .vehicle-card-details-box p {
  margin: 0;
  font-size: 13px;
  text-align: left;
  width: 100%;
  color: #4D4D4D;
}
.cars-items-card-box .vehicle-card-details-box .vehicle-card-lot {
  color: var(--Secondary-700, rgba(45, 45, 45, 0.7));
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.cars-items-card-box .vehicle-card-details-box h6 {
  text-align: left;
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 700;
  width: 100%;
}
.cars-items-card-box .vehicle-card-details-box button {
  width: 90px;
}
.cars-items-card-box .vehicle-card-details-box .vehicle-card-bid-button {
  margin-bottom: 5px;
}
.cars-items-card-box .vehicle-card-amounts {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}
.cars-items-card-box .vehicle-card-action-buttons {
  display: flex;
  flex-direction: column;
}
.cars-items-card-box .vehicle-card-car-details-hr {
  display: flex;
  margin-top: 17px;
  justify-content: space-between;
  width: 100%;
}
.cars-items-card-box .vehicle-card-car-details-hr-icon {
  height: 24px;
}
.cars-items-card-box .vehicle-card-car-details-hr-val {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.cars-items-card-box .vehicle-card-car-details-hr-val span {
  color: #808080;
  font-size: 12px;
}
.cars-items-card-box .vehicle-card-car-details-hr-val p {
  color: var(--Grayscale-900, #000);
  font-size: 12px;
  font-weight: 700;
}
.cars-items-card-box .vehicle-card-vr-line {
  width: 1px;
  height: 155px;
  background: #E1E1E1;
}
.cars-items-card-box .vehicle-card-amount-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid var(--Grayscale-100, #E6E6E6);
  width: 264px;
  height: 58px;
  margin-top: 16px;
  padding: 0 12px;
}
.cars-items-card-box .vehicle-card-current-bid, .cars-items-card-box .vehicle-card-buy-now-price {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.cars-items-card-box .vehicle-card-current-bid-title, .cars-items-card-box .vehicle-card-buy-now-title {
  color: var(--Grayscale-600, #666);
  text-align: center;
  font-family: "HelveticaNeue";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
}
.cars-items-card-box .vehicle-card-current-bid-amount {
  color: rgba(61, 146, 58, 0.6) !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.cars-items-card-box .vehicle-card-buy-now-amount {
  color: #000 !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.cars-items-card-box .vehicle-card-save-car-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid var(--Grayscale-100, #E6E6E6);
  background: white;
  cursor: pointer;
}
.cars-items-card-box .vehicle-card-save-car-content {
  display: flex;
  align-items: center;
}
.cars-items-card-box .vehicle-card-save-car-content span {
  margin-right: 8px;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
}
.cars-items-card-box .vehicle-card-bids {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 165px;
  justify-content: space-between;
}
.cars-items-card-box .vehicle-card-show-details-button {
  display: flex;
  align-items: center;
  width: 193px;
  height: 56px;
  border: 1px solid rgba(90, 126, 255, 0.8);
  background: none;
  border-radius: 16px;
  justify-content: center;
}
.cars-items-card-box .vehicle-card-show-details-button span {
  color: rgba(90, 126, 255, 0.9);
  margin-right: 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.catalogue-content-container {
  display: flex;
  justify-content: center;
}
.catalogue-content-container .vehicle-list-container {
  width: 96%;
  max-width: 1396px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}
.catalogue-content-container .vehicle-list-container a {
  color: unset !important;
  text-decoration: none !important;
}
.catalogue-content-container .vehicle-list-container .cars-items-card-box-cont {
  width: calc(25% - 21px);
  margin-right: 0 !important;
}

.showing-results-count {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  margin-bottom: 100px;
}

.not-found-result-text {
  font-size: 22px;
  margin-bottom: 80px;
  color: gray;
}

@media (max-width: 1024px) {
  .vehicle-card-thumbnail {
    width: 166px;
    height: 126px;
  }
  .cars-items-card-box-cont {
    width: 222px;
    min-width: 222px;
  }
  .cars-items-card-box {
    width: 222px;
    min-width: 222px;
    padding: 16px 28px;
  }
  .vehicle-card-car-name {
    font-size: 14px;
    font-weight: bold;
    max-width: 166px;
    white-space: pre-line !important;
  }
  .vehicle-card-lot {
    font-size: 8px;
  }
  .add-to-favorite-populars {
    margin-top: 28px !important;
    margin-right: 40px !important;
    width: 22px !important;
    height: 22px !important;
  }
  .veh-card-item-mobile span {
    font-size: 10px;
  }
  .main-page-recomended-lots-container .cars-items-card-box-cont {
    max-height: 305px;
  }
  .car-list-catalogue {
    min-width: 328px;
  }
  .car-list-catalogue .cars-items-card-box {
    width: 100%;
  }
  .car-list-catalogue .cars-items-card-box .vehicle-card-thumbnail {
    width: 264px;
    height: 199px;
  }
  .car-list-catalogue .add-to-favorite-populars {
    width: 36px !important;
    height: 36px !important;
  }
  .catalogue-page-filter-container .filter-divider {
    display: none;
  }
  .catalogue-page-filter-container .form-control-select {
    height: 50px;
  }
  .catalogue-page-filter-container .form-control-select-box {
    height: 50px;
  }
  .catalogue-page-filter-container .catalogue-filter-item-box {
    margin-bottom: 12px;
  }
  .catalogue-page-filter-container .form-control-select-dropdown {
    margin-top: 10px;
  }
}
@media (max-width: 700px) {
  .vehicle-list-container {
    justify-content: center !important;
  }
}/*# sourceMappingURL=VehicleList.css.map */