.popular-brands-list-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 112px 0 112px 0;
    margin-bottom: 184px;
    width: 100%;
    .vehicle-type-selector-head{
        margin: 0;
    }
    .popular-brands-list-banner{
        width: 1204px;
        height: 505px;
        border-radius: 12px;
        background: #F9FBFC;
        position: absolute;
    }
    .premium-brands-list{
        margin-top: 52px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        z-index: 0;
    }
    .brand-image-box{
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .popular-brand-item{
        border-radius: 16px;
        z-index: 11;
        position: relative;
        background: #FFF;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
        display: flex;
        width: 206px;
        height: 180px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 16px;
        cursor: pointer;
        &:hover{
            color: #5A7EFF !important;
            transition: 0.5s;
            border: 1px solid #5A7EFF;
        }
    }
    .permium-brand-name{
        text-align: center;
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
    }
}

@media(max-width: 1024px){
    .popular-brands-list-container{
        overflow: hidden;
        .premium-brands-list{
            overflow: auto;
            &::-webkit-scrollbar{
                display: none;
            }
        }
        .popular-brand-item{
            margin-right: 16px;
            width: 112px;
            height: 112px;
            min-width: 112px;
            max-height: 112px;
            img{
                height: 40px;
            }
        }
        .permium-brand-name{
            font-size: 10px;
            line-height: unset;
        }
    }
}