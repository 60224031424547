.header-container{
    background: #F6F9FB;
    width: 100%;
    height: 192px;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08);
    position: relative;
    z-index: 11;
    .header-content{
        width: 96%;
        max-width: 1396px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .navigation-container{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 79px;
    }
    .navbar-content{
        width: 96%;
        max-width: 1396px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        ul{
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            a{
                text-decoration: none;
                color: #000;
                font-family: "HelveticaNeue";
                font-size: 16px;
                font-weight: 400;
                font-style: normal;
                line-height: 26px;
                margin-right: 32px;
            }
            .active-tab{
                color: #5A7EFF;
                font-weight: 700;
            }
        }
    }
    .header-content-head{
        width: 100%;
        height: 112px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .header-divider{
        background: rgba(153, 153, 153, 1);
        height: 1px;
        width: 100%;
        opacity: 0.15;
    }
    .header-logo-icon{
        width: 100px;
    }
    .form-group-searchbox{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 50px;
    }
    .header-loop{
        width: 24px;
        position: absolute;
        margin-left: 15px;
        cursor: pointer;
    }
    .header-search-input-box{
        width: 337px;
        height: 48px;
        outline: none;
        border: 1px solid rgba(204, 204, 204, 1);
        background: none;
        border-radius: 16px;
        padding: 0 16px 0 52px;
        font-size: 16px;
    }
    .login-button{
        width: 216px;
        height: 56px;
        border: 1px solid rgba(204, 204, 204, 1);
        border-radius: 16px;
        background: none;
        font-size: 16px;
        color: rgba(0, 0, 0, 1);
        font-weight: 500;
        font-size: 16px;
        margin-right: 24px;
        img{
            margin-right: 8px;
        }
    }
    .selected-language-box{
        border-radius: 16px;
        border: 1px solid var(--Grayscale-200, #CCC);
        display: flex;
        width: 216px;
        height: 56px;
        padding: 0 16px;
        align-items: center;
        cursor: pointer;
        justify-content: space-between;
        p{
            margin: 0;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px;
        }
        .selected-language-content{
            display: flex;
            align-items: center;
            img{
                margin-right: 8px;
            }
        }
    }
    .choose-language-dropdown{
        position: absolute;
        background: white;
        width: 216px;
        margin-top: 8px;
        border-radius: 16px;
        z-index: 111;
        .language-list-card{
            display: flex;
            align-items: center;
            padding: 8px 0 8px 16px;
            cursor: pointer;
            p{
                margin: 0 0 0 8px;
            }
        }
    }
}

.logged-in-user-menu-button-container{
    .login-button{
        width: fit-content !important;
        padding: 16px;
        span{
            margin-right: 15px;
        }
        img{
            margin-right: 0;
        }
        .profile-img{
            margin-right: 8px;
        }
    }
}

.profile-dropdown-container{
    width: fit-content;
    padding: 23px 22px 31px 22px;
    background: white;
    box-shadow: 0px 6px 15px 0px rgba(64, 79, 104, 0.05);
    border-radius: 16px;
    margin-top: 20px;
    z-index: 111111;
    position: absolute;
    .profile-dropdown-container-square{
        width: 12px;
        height: 12px;
        position: absolute;
        background: white;
        margin-top: -28px;
        transform: rotate(45deg);
        margin-left: calc(100% - 70px);
    }
    .profile-dropdown-user-info-image{
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        background: #000;
        margin-right: 8px;
    }
    .profile-dropdown-user-info{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 24px;
    }
    .profile-dropdown-user-info-detail{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .profile-dropdown-user-info-fullname{
        color: var(--Grayscale-900, #000);
        leading-trim: both;
        text-edge: cap;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
    .profile-dropdown-user-info-phone{
        color: var(--Grayscale-400, #999);
        leading-trim: both;
        text-edge: cap;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
    .profile-dropdown-line{
        height: 1px;
        width: 100%;
        background: rgba(230, 230, 230, 0.50);
    }
    .profile-dropdown-list-menu-items{
        a{
            display: flex;
            align-items: center;
            text-decoration: none;
            margin-top: 15px;
        }
        .profile-dropdown-list-menu-item-icon{
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background: rgba(230, 230, 230, 0.40);
            padding: 8px;
            margin-right: 12px;
        }
        p{
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            color: black;
            text-decoration: none;
            margin: 0;
        }
    }
    .profile-dropdown-list-logout{
        padding: 8px;
        width: 32px;
        height: 32px;
        border-radius: 26px;
        background: var(--Background-System-Negative, rgba(229, 62, 51, 0.10));
    }
    .logout-text{
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        color: black;
        text-decoration: none;
        margin: 0 0 0 12px;
    }
}

.mobile-header-buttons{
    display: none;
    img{
        cursor: pointer;
    }
}

.watched-in-list{
    background: #5A7EFF !important;
}

.mobile-navigation-container{
    width: 100vw;
    height: calc(100vh - 92px);
    position: fixed;
    background: white;
    z-index: 111111;
    display: none;
}

@media (max-width: 1024px){
    .header-container{
        height: 92px;
        .header-logo-icon{
            width: 62px;
        }
        .header-content-head{
            height: 92px;
        }
        .header-divider, .navigation-container, .form-group-searchbox, .login-button, .language-switcher-container{
            display: none;
        }
        .mobile-header-buttons{
            display: flex;
            align-items: center;
        }
        .header-loop{
            margin-left: 0;
            margin-right: 25px;
            position: relative;
        }
    }
}