.favorite-vehicle-card-container{
    width: 100%;
    padding: 22px;
    background: white;
    border-radius: 16px;
    margin-bottom: 24px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .favorites-card-image{
        width: 282px;
        height: 252px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 16px;
    }
    .favorites-card-right-side{
        border-left: #E1E1E1 1px solid;
        padding-left: 16px;
    }
    .favorites-card-details-info{
        width: calc(100% - 298px);
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
    .favorites-card-left-side{
        max-width: 400px;
        .favorites-card-make{
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            margin-bottom: 8px;
            text-align: left;
        }
        .favorites-card-name{
            color: var(--Color-Primary, #050B20);
            leading-trim: both;
            text-edge: cap;
            text-overflow: ellipsis;
            text-align: left;
            white-space: nowrap;
            font-family: "Helvetica Neue";
            font-size: 14px;
            font-style: normal;
            line-height: 14px;
            font-weight: 400;
            margin-bottom: 0;
            margin-bottom: 8px;
        }
        .favorites-card-lot{
            font-size: 12px;
            line-height: 14px;
            font-style: normal;
            font-weight: 400;
            color: var(--Grayscale-700, #4D4D4D);
            text-align: left;
        }
    }
    .favorites-card-lot-details-item{
        display: flex;
        align-items: center;
    }
    .favorites-card-lot-details{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 42px;
    }
    .favorites-card-lot-details-description{
        margin-left: 8px;
        margin-right: 16px;
        p{
            text-align: left;
        }
    }
    .favorites-card-lot-details-title{
        color: var(--Grayscale-500, #808080);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 4px;
        line-height: 12px;
    }
    .favorites-card-lot-details-value{
        font-size: 12px;
        margin-bottom: 0;
        line-height: 12px;
        font-style: normal;
        font-weight: 400;
    }
    .favorites-card-auction-date-title{
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        margin-bottom: 12px;
        text-align: left;
    }
    .favorites-card-auction-date-value{
        color: var(--Grayscale-500, #808080);
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        margin-bottom: 0;
        margin-left: 8px;
    }
    .favorites-card-vehicle-bid-details{
        width: 264px;
        height: 58px;
        flex-shrink: 0;
        border-radius: 8px;
        border: 1px solid var(--Grayscale-100, #E6E6E6);
        padding: 12px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 42px;
    }
    .favorites-card-vehicle-bid-details-title{
        color: var(--Grayscale-600, #666);
        text-align: left;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 2px;
    }
    .favorites-card-vehicle-bid-details-value{
        color: var(--System-Positive-600, rgba(61, 146, 58, 0.60));
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        text-align: left;
        line-height: 24px;
    }
    .favorites-card-vehicle-page{
        border-radius: 8px;
        border: 1px solid var(--Primary-800, rgba(90, 126, 255, 0.80));
        display: flex;
        width: 264px;
        height: 56px;
        padding: 16px 32px;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        margin-top: 42px;
        p{
            margin-bottom: 0;
            margin-right: 16px;
            color: var(--Primary-900, rgba(90, 126, 255, 0.90));
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
        }
    }
    .favorites-card-watch-button{
        border-radius: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--Background-Primary, rgba(90, 126, 255, 0.10));
        /* sh-3 */
        box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.05);
        width: 36px;
        height: 36px;
    }
    .favorites-card-auction-info-details-box{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
}