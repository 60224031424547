.banner-content-container {
  width: 100%;
  overflow: hidden;
  background: var(--Background-Primary, rgba(90, 126, 255, 0.1));
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.banner-content-container .banner-container-circle {
  width: 816px;
  height: 816px;
  background: none;
  border-radius: 50%;
  border: 100px solid #050B20;
  position: absolute;
  opacity: 0.02;
}
.banner-content-container .banner-subtitle {
  text-align: center;
  color: var(--Grayscale-900, #000);
  text-align: center;
  font-family: "HelveticaNeue";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 48px;
}
.banner-content-container .banner-title {
  color: var(--Grayscale-900, #000);
  text-align: center;
  font-family: "HelveticaNeue";
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 96%;
  max-width: 730px;
}

.banner-car-image {
  margin-top: -170px;
}/*# sourceMappingURL=Banner.css.map */