.main-page-recomended-lots-container {
  display: block;
  width: 100%;
  overflow: hidden;
  margin-bottom: 32px;
}
.main-page-recomended-lots-container a {
  text-decoration: none;
  color: unset;
}
.main-page-recomended-lots-container .main-page-recomended-lots-carousel {
  display: flex;
  align-items: flex-start;
  transition: 0.7s;
}
.main-page-recomended-lots-container .cars-items-card-box {
  margin-bottom: 0 !important;
}

.main-page-recomended-lot-slider-buttons {
  display: flex;
  align-items: center;
  margin-bottom: 184px;
}
.main-page-recomended-lot-slider-buttons .main-page-recomended-lot-slider-button {
  display: flex;
  width: 56px;
  padding: 14px 22px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 30px;
  border: 1px solid var(--Grayscale-100, #E6E6E6);
  background: none;
}

.add-to-favorite-populars {
  position: absolute;
  margin: 46px 44px 0 0;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: none;
  background: rgba(255, 255, 255, 0.9);
}/*# sourceMappingURL=PopularVehicles.css.map */