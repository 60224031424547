.main-page-contact-form {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.main-page-contact-form .form-label {
  position: absolute;
  color: var(--Grayscale-300, #B3B3B3);
  font-family: "HelveticaNeue";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding-top: 23px;
  padding-left: 23px;
  transition: 0.4s;
  z-index: 1;
}
.main-page-contact-form .form-control {
  padding: 16px;
  padding-top: 28px;
  height: 64px;
  background: none;
  border-radius: 12px;
  border: 1px solid var(--Grayscale-100, #E6E6E6);
  gap: 4px;
}
.main-page-contact-form .label-blured {
  padding-top: 8px;
  padding-left: 16px;
}
.main-page-contact-form .form-field-divided {
  width: calc(50% - 21px) !important;
}
.main-page-contact-form .form-group {
  margin-bottom: 32px;
}
.main-page-contact-form .form-control-textarea {
  width: 100%;
  height: 258px;
  border-radius: 12px;
  border: 1px solid var(--Grayscale-100, #E6E6E6);
  outline: none;
  text-align: left;
  padding: 25px 16px 16px 16px;
  color: var(--Grayscale-900, #000);
  font-family: "HelveticaNeue";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 111.111% */
  letter-spacing: 0.36px;
}

.form-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.main-page-contact-info {
  width: 566px;
  height: 530px;
  border-radius: 16px;
  border: 1px solid var(--Grayscale-100, #E6E6E6);
  margin-top: -81px;
  padding: 32px;
}

.main-page-contact-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.main-page-contact-info-title {
  color: var(--Grayscale-900, #000);
  font-family: "HelveticaNeue";
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  line-height: 20px;
  margin-bottom: 42px;
  text-align: left;
}

.main-page-contact-info-detail {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}
.main-page-contact-info-detail svg {
  margin-right: 12px;
}
.main-page-contact-info-detail span {
  color: var(--Grayscale-900, #000);
  font-family: "HelveticaNeue";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 112.5% */
  letter-spacing: 0.32px;
}

.main-page-contact-info-working-hours {
  color: var(--Grayscale-900, #000);
  font-family: "HelveticaNeue";
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  text-align: left;
  margin-bottom: 24px;
  line-height: 20px;
}

.main-page-working-hours {
  color: var(--Grayscale-900, #000);
  font-family: "HelveticaNeue";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 112.5% */
  letter-spacing: 0.32px;
  margin-bottom: 8px;
  text-align: left;
}

.follow-us-title {
  color: var(--Grayscale-900, #000);
  font-family: "HelveticaNeue";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 16px;
  margin-top: 48px;
  text-align: left;
}

.main-page-contact-socials {
  display: flex;
  align-items: center;
}
.main-page-contact-socials svg {
  margin-right: 16px;
}
.main-page-contact-socials .twitter-box {
  border-radius: 50px;
  width: 44px;
  height: 44px;
  flex-shrink: 0;
  background: var(--Primary-900, rgba(90, 126, 255, 0.1));
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}
.main-page-contact-socials .twitter-box svg {
  margin: 0;
}

.main-page-send-message-button {
  display: flex;
  width: 244px;
  height: 64px;
  justify-content: center;
  align-items: center;
  gap: 32px;
  flex-shrink: 0;
  border: none;
  border-radius: 16px;
  background: var(--Gradient-Main-Main, linear-gradient(254deg, #75D4FD -141.51%, #5A7EFF 108.14%));
  color: white;
  margin-top: 20px;
  margin-bottom: 250px;
}

@media (max-width: 1024px) {
  .main-page-contact-content {
    flex-direction: column;
  }
  .main-page-contact-content .form-group {
    width: 100% !important;
    margin-bottom: 24px;
  }
  .main-page-contact-info {
    margin-top: 42px;
    width: 100%;
  }
  .main-page-contact-info span {
    font-size: 14px !important;
  }
  .main-page-contact-form {
    width: 100%;
  }
  .main-page-send-message-button-mobile {
    margin-bottom: 0px !important;
  }
  .main-page-contact-info-mobile {
    margin-bottom: 42px !important;
  }
}/*# sourceMappingURL=MainPageContact.css.map */