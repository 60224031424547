.vehicle-type-selector-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 44px;
    margin-top: 140px;
    z-index: 1;
    position: relative;
    width: 100%;
}

.vehicle-type-selector-title{
    color: var(--Grayscale-900, #000);
    font-family: "HelveticaNeue";
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.view-all-button{
    color: var(--Grayscale-900, #000);
    font-family: "HelveticaNeue";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.vehicle-type-selector-items{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 184px;
}

.vehicle-type-selector-item{
    width: 260px;
    height: 300px;
    flex-shrink: 0;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 42px 24px;
    background-position: center;
    cursor: pointer;
    p{
        margin-bottom: 16px;
        text-align: left;
    }
}

.vehicle-type-selector-item-counter{
    color: #FFF;
    font-family: "HelveticaNeue";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.vehicle-type-selector-item-type{
    color: #FFF;
    font-family: "HelveticaNeue";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

@media(max-width: 1465px){
    .vehicle-type-selector-item{
        margin-right: 16px;
    }
    .vehicle-type-selector-items{
        overflow: auto;
        &::-webkit-scrollbar{
            display: none;
        }
    }
}

@media(max-width: 1024px){
    .vehicle-type-selector-item{
        width: 146px;
        height: 164px;
        padding: 24px 16px;
        p{
            font-size: 12px;
            margin-bottom: 6px;
        }
    }
    .vehicle-type-selector-title{
        font-size: 12px;
    }
    .view-all-button{
        font-size: 8px;
    }
    .vehicle-type-selector-head{
        margin: 17px auto 24px auto;
    }
    .vehicle-type-selector-items{
        margin-bottom: 56px;
    }
    .why-us-container-background{
        width: calc(100vw - 48px) !important;
        height: 384px !important;
        overflow: hidden;
    }
}