.text-editor-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.text-editor-container .text-editor-box {
  width: 100%;
  margin: auto;
  flex: 1;
  overflow: auto;
  padding-top: 15px;
}
.text-editor-container .text-editor-box::-webkit-scrollbar {
  width: 10px;
}
.text-editor-container .text-editor-box::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.text-editor-container .text-editor-box::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.text-editor-container .text-editor-box::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.text-editor-body {
  width: 100%;
  border: 1px solid rgb(224, 224, 224);
  border-radius: 8px;
  padding: 0 16px 16px 16px;
  display: flex;
  flex-direction: column;
  height: 358px;
}

.toolbar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid rgb(226, 226, 226);
}
.toolbar button {
  height: 40px;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 40px;
  background: rgb(182, 182, 182);
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 12px;
  margin: 5px;
}
.toolbar .active {
  background: rgb(73, 73, 73);
}

.editor .public-DraftStyleDefault-block {
  font-size: 14px; /* Default font size */
}

.editor .public-DraftStyleDefault-FONTSIZE-10 {
  font-size: 10px;
}

.editor .public-DraftStyleDefault-FONTSIZE-11 {
  font-size: 11px;
}

.editor .public-DraftStyleDefault-FONTSIZE-12 {
  font-size: 12px;
}

.editor .public-DraftStyleDefault-FONTSIZE-13 {
  font-size: 13px;
}

.editor .public-DraftStyleDefault-FONTSIZE-15 {
  font-size: 15px;
}

.editor .public-DraftStyleDefault-FONTSIZE-16 {
  font-size: 16px;
}

.editor .public-DraftStyleDefault-FONTSIZE-17 {
  font-size: 17px;
}

.editor .public-DraftStyleDefault-FONTSIZE-18 {
  font-size: 18px;
}

.editor .public-DraftStyleDefault-FONTSIZE-19 {
  font-size: 19px;
}

.editor .public-DraftStyleDefault-FONTSIZE-20 {
  font-size: 20px;
}

.editor .public-DraftStyleDefault-FONTSIZE-21 {
  font-size: 21px;
}

.editor .public-DraftStyleDefault-FONTSIZE-22 {
  font-size: 22px;
}

.editor .public-DraftStyleDefault-FONTSIZE-23 {
  font-size: 23px;
}

.editor .public-DraftStyleDefault-FONTSIZE-24 {
  font-size: 24px;
}

.editor .public-DraftStyleDefault-FONTSIZE-25 {
  font-size: 25px;
}

.editor .public-DraftStyleDefault-FONTSIZE-26 {
  font-size: 26px;
}

.editor .public-DraftStyleDefault-FONTSIZE-27 {
  font-size: 27px;
}

.editor .public-DraftStyleDefault-FONTSIZE-28 {
  font-size: 28px;
}

.editor .public-DraftStyleDefault-FONTSIZE-29 {
  font-size: 29px;
}

.editor .public-DraftStyleDefault-FONTSIZE-30 {
  font-size: 30px;
}

.editor .public-DraftStyleDefault-FONTSIZE-31 {
  font-size: 31px;
}

.editor .public-DraftStyleDefault-FONTSIZE-32 {
  font-size: 32px;
}

.editor .public-DraftStyleDefault-FONTSIZE-33 {
  font-size: 33px;
}

.editor .public-DraftStyleDefault-FONTSIZE-34 {
  font-size: 34px;
}

.editor .public-DraftStyleDefault-FONTSIZE-35 {
  font-size: 35px;
}

.editor .public-DraftStyleDefault-COLOR-RED {
  color: red;
}

.editor .public-DraftStyleDefault-COLOR-BLUE {
  color: blue;
}

.editor .public-DraftStyleDefault-COLOR-GREEN {
  color: green;
}

.editor .public-DraftStyleDefault-COLOR-YELLOWGREEN {
  color: yellowgreen;
}

.editor .public-DraftStyleDefault-COLOR-BLACK {
  color: black;
}

.editor .public-DraftStyleDefault-COLOR-YELLOW {
  color: yellow;
}

.editor .public-DraftStyleDefault-COLOR-GRAY {
  color: gray;
}

.upload-image-button {
  width: 200px;
  height: 50px;
  background: var(--Gradient-Main-Main, linear-gradient(254deg, #75D4FD -141.51%, #5A7EFF 108.14%));
  border: none;
  border-radius: 12px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px 0;
}

.save-content-button {
  width: 100%;
  height: 55px;
  max-width: 100%;
  border: 1px solid #5A7EFF;
  border-radius: 12px;
  background: none;
  color: #5A7EFF;
  margin-bottom: 30px;
}/*# sourceMappingURL=TextEditor.css.map */