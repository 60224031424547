.why-choose-us-container{
    height: 625px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .why-us-container-background{
        width: 1204px;
        height: 100%;
        background: rgba(90, 126, 255, 0.05);
        position: absolute;
        z-index: -1;
        border-radius: 16px;
    }
    .why-choose-us-title{
        color: var(--Grayscale-900, #000);
        font-family: "HelveticaNeue";
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 45px;
    }
    .why-choose-us-item{
        width: 328px;
        height: 300px;
        border-radius: 16px;
        background: white;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 42px;
    }
    .why-choose-us-list{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 52px;
        overflow: auto;
        &::-webkit-scrollbar{
            display: none;
        }
        p{
            text-align: left;
            width: 100%;
        }
    }
    .why-choose-us-item-title{
        margin: 26px 0;
    }
    .why-choose-us-item-description{
        color: var(--Grayscale-800, #333);
        font-family: "HelveticaNeue";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        margin-bottom: 0;
    }
}

@media(max-width: 1024px){
    .why-choose-us-container{
        height: unset;
        padding-top: 56px;
        .why-choose-us-title{
            font-size: 20px;
        }
        .why-choose-us-item{
            width: 112px;
            height: 146px;
            padding: 14px 12px;
            min-width: 112px;
            img{
                width: 40px;
                height: 40px;
            }
            .why-choose-us-item-title{
                font-size: 8px;
                margin: 9px 0;
                line-height: 9px;
                overflow: hidden;
            }
            .why-choose-us-item-description{
                font-size: 6px;
                line-height: 8px;
            }
        }
        .benefits{
            padding: 14px 7px;
        }
    }
}

@media(max-width: 1024px){
    .why-choose-us-item{
        margin-right: 15px;
    }
}