.calculator-dropdown-container {
  width: calc(50% - 16px);
  margin-bottom: 24px;
  position: relative;
}
.calculator-dropdown-container .calculator-dropdown-search {
  height: 45px;
  padding-left: 15px;
  margin-bottom: 15px;
  border: 1px solid var(--Grayscale-100, #E6E6E6);
  border-radius: 12px;
  background: none;
  outline: none;
  margin-top: 20px;
}
.calculator-dropdown-container .calculator-dropdown-select-box {
  background: white;
  height: 64px;
  border-radius: 12px;
  padding: 16px;
  cursor: pointer;
}
.calculator-dropdown-container .calculator-dropdown-select-box .calculator-dropdown-label {
  color: var(--Grayscale-300, #B3B3B3);
  font-family: "HelveticaNeue";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 4px;
  line-height: 12px;
  text-align: left;
}
.calculator-dropdown-container .calculator-dropdown-select-box .calculator-dropdown-active-value {
  color: var(--Grayscale-900, #000);
  font-family: "HelveticaNeue";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.32px;
  line-height: 16px;
  margin: 0;
}
.calculator-dropdown-container .calculator-dropdown-selected-value {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.calculator-dropdown-container .calculator-dropdown-list-box {
  position: absolute;
  z-index: 1;
  background: white;
  max-height: 320px;
  overflow: auto;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  margin-top: 5px;
  overflow: auto;
}
.calculator-dropdown-container .calculator-dropdown-list-box .records-not-found {
  color: rgb(199, 199, 199);
  font-size: 13px;
  margin: 0;
  padding: 13px 0;
}
.calculator-dropdown-container .calculator-dropdown-list-box .calculator-dropdown-item {
  margin: 0;
  padding: 6px;
  cursor: pointer;
}
.calculator-dropdown-container .calculator-dropdown-list-box .calculator-dropdown-item:hover {
  background: var(--Gradient-Main-Main, linear-gradient(254deg, #75D4FD -141.51%, #5A7EFF 108.14%));
  transition: 0.5s;
  color: white;
}/*# sourceMappingURL=CalculatorDropdown.css.map */