.auth-page-container{
    width: 100vw;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1111;
    height: 100vh;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    opacity: 0;
    transition: 1s;
    .form-control-select{
        width: 100%;
    }
    .form-control-select-box {
        width: 100%;
        height: 56px;
        justify-content: space-between;
        border-radius: 12px;
        border: 1px solid var(--Grayscale-100, #E6E6E6);
        padding: 16px;
        display: flex;
        cursor: pointer;
        align-items: center;
        p{
            text-align: left;
            margin-bottom: 0;
            font-size: 16px;
            color: var(--Grayscale-400, #999);
        }
    }
    .form-control-select-dropdown{
        margin-top: 8px;
        width: 100%;
        overflow: auto;
        height: fit-content;
        z-index: 111111;
        max-height: 400px;
        position: absolute;
        background: white;
        margin-top: 8px !important;
        border-radius: 16px;
        background: var(--Shadow-XS, #FFF);
        margin-top: 34px;
        /* Shadow/XS */
        box-shadow: 0px 1px 0px 0px var(--Alpha-50, rgba(0, 0, 0, 0.04)), 0px 1px 4px 0px var(--Alpha-100, rgba(0, 0, 0, 0.08));
        // &::-webkit-scrollbar-button{
        //     width: 5px;
        //     background: gray;
        //     border-radius: 2px;
        // }
        // &::-webkit-scrollbar-corner{
        //     width: 5px;
        //     background: gray;
        //     border-radius: 2px;
        // }
        // &::-webkit-scrollbar-track{
        //     width: 5px;
        //     background: gray;
        //     border-radius: 2px;
        // }
        // &::-webkit-scrollbar-track-piece{
        //     width: 5px;
        //     background: gray;
        //     border-radius: 2px;
        // }
        &::-webkit-scrollbar {
            width: 10px;
        }
        &::-webkit-scrollbar-track {
            background: #f1f1f1; 
        }
        &::-webkit-scrollbar-thumb {
            background: #888; 
              border-radius:10px;
        }
        &::-webkit-scrollbar-thumb:hover {    
            background: #555; 
        } 
    }
    .auth-page-content{
        width: 788px;
        height: 100%;
        background: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: auto;
        padding-bottom: 56px;
        &::-webkit-scrollbar {
            width: 8px;
        }
        &::-webkit-scrollbar-track {
            background: white; 
        }
        &::-webkit-scrollbar-thumb {
            background: #888; 
            border-radius:10px;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: var(--Grayscale-200, #CCC);; 
        } 
    }
    .auth-page-logo{
        width: 150px;
        margin: 90px 0;
        cursor: pointer;
    }
    .auth-page-title{
        margin-top: 50px;
        color: var(--Grayscale-900, #000);
        text-align: center;
        font-family: "HelveticaNeue";
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        margin-bottom:32px;
    }
    .auth-page-banner{
        width: calc(100% - 788px);
        height: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        background-image: url('https://carspace-app.crg.ge/static/auth-banner.png');
    }
    .signup-page-banner{
        width: calc(100% - 788px);
        height: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url('https://carspace-app.crg.ge/static/signup-banner.png');
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
    }
    .form-label{
        position: absolute;
        color: var(--Grayscale-400, #999);
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        padding-top: 24px;
        padding-left: 23px;
        transition: 0.4s;
        z-index: 0;
    }
    .form-label-with-icon{
        position: absolute;
        color: var(--Grayscale-300, #B3B3B3);
        font-family: "HelveticaNeue";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        padding-top: 24px;
        padding-left: 49px;
        transition: 0.4s;
        z-index: 0;
    }
    .form-control{
        padding: 16px;
        padding-top: 28px;
        height: 64px;
        background: none;
        border-radius: 12px;
        border: 1px solid var(--Grayscale-100, #E6E6E6);
        gap: 4px;
        z-index: 1;
    }
    .label-blured{
        padding-top: 8px;
        padding-left: 16px;
        z-index: 11;
    }
    .form-field-divided{
        width: 96% !important;
        max-width: 524px;
    }
    .form-group{
        margin-bottom: 32px;
        position: relative;
    }
    .form-control-textarea{
        width: 100%;
        height: 258px;
        border-radius: 12px;
        border: 1px solid var(--Grayscale-100, #E6E6E6);
        outline: none;
        text-align: left;
        padding: 25px 16px 16px 16px;
        color: var(--Grayscale-900, #000);
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 111.111% */
        letter-spacing: 0.36px;
    }
    .reset-password-text{
        margin-top: -8px;
        text-align: right;
        width: 96%;
        max-width: 492px;
        color: var(--Primary-800, rgba(90, 126, 255, 0.80));
        font-family: "HelveticaNeue";
        font-size: 20px;
        cursor: pointer;
        font-style: normal;
        font-weight: 400;
        line-height: 16px
    }
    .login-form-submit-button{
        border-radius: 8px;
        padding: 16px 0;
        background: var(--Gradient-Main-Main, linear-gradient(254deg, #75D4FD -141.51%, #5A7EFF 108.14%));
        display: flex;
        height: 64px;
        width: 96%;
        max-width: 524px;
        border: none;
        justify-content: center;
        align-items: center;
        gap: 32px;
        color: var(--Base-white-900, rgba(255, 255, 255, 0.90));
        text-align: center;
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        text-transform: uppercase;
        margin-top: 48px;
    }
    .login-form-signup-button{
        padding: 16px 0;
        border-radius: 8px;
        display: flex;
        height: 64px;
        width: 96%;
        max-width: 524px;
        border: 1px solid var(--Grayscale-100, #E6E6E6);
        justify-content: center;
        align-items: center;
        gap: 32px;
        color: black;
        text-align: center;
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        text-transform: uppercase;
        margin-top: 16px;
        background: none;
    }
}

.auth-animation{
    opacity: 1 !important;
}

.input-with-left-icon{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.input-with-two-icon{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.input-with-right-icon{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.years-item-signup{
    padding: 5px 0 0 35px;
    text-align: left;
    cursor: pointer;
    &:hover{
        background: rgb(229, 229, 229);
    }
}

.gender-selector-container{
    margin-top: 24px;
}

.gender-select-label{
    margin: 0 32px 0 8px;
    cursor: pointer;
}

.error-text{
    color: var(--System-Negative-600, #E53E33);
    font-family: "HelveticaNeue";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    position: absolute;
    margin-top: 68px;
}

.success-text{
    color: var(--System-Negative-600, #0bde2b);
    font-family: "HelveticaNeue";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    position: absolute;
    margin-top: 68px;
}

.send-message-button{
    position: absolute;
    z-index: 1111;
    width: 148px;
    height: 40px;
    border-radius: 4px;
    background: var(--Grayscale-100, #E6E6E6);
    border: none;
    color: var(--Grayscale-300, #B3B3B3);
    margin-right: 16px;
}
.send-message-button-active{
    position: absolute;
    z-index: 1111;
    width: 148px;
    height: 40px;
    border-radius: 4px;
    background: var(--Gradient-Main-Main, linear-gradient(254deg, #75D4FD -141.51%, #5A7EFF 108.14%));
    border: none;
    color: white;
    margin-right: 16px;
}
.signup-accepts{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 12px;
    cursor: pointer;
    label{
        margin: 0 5px;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        color: #999;
        line-height: 16px;
    }
    a{
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        color: var(--Primary-800, rgba(90, 126, 255, 0.80));
        text-decoration: none;
    }
}
.signup-checkbox{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 96%;
    max-width: 524px;
}

.signup-section-divider{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 24px 0 32px 0;
    width: 96%;
    max-width: 524px;
    .signup-modal-hr{
        width: calc((100% / 2) - 22px);
        height: 1px;
        background: var(--Grayscale-100, #E6E6E6);
    }
    span{
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
}
.signup-by-social{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 96%;
    max-width: 524px;
    button{
        width: 160px;
        height: 64px;
        border-radius: 4px;
        border: 1px solid var(--Grayscale-100, #E6E6E6);
        background: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 16px;
        span{
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            margin-left: 12px;
        }
    }
}