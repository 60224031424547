.footer-container{
    width: 100%;
    height: 647px;
    background: rgba(5, 11, 32, 1);
    padding: 48px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.subscribe-news-input{
    width: 568px;
    height: 80px;
    flex-shrink: 0;
    background: rgba($color: #ffffff, $alpha: 0.1);
    color: white;
    font-size: 16px;
    border: none;
    outline: none;
    border-radius: 16px;
    padding-left: 24px;
}

.subscribe-news-footer{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.footer-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 96%;
    max-width: 1396px;
}

.subscribe-news-button{
    display: flex;
    width: 154px;
    height: 56px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;
    border-radius: 16px;
    background: var(--Gradient-Main-Main, linear-gradient(254deg, #75D4FD -141.51%, #5A7EFF 108.14%));
    color: var(--Base-white-900, rgba(255, 255, 255, 0.90));
    text-align: center;
    font-family: "HelveticaNeue";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 125% */
    text-transform: uppercase;
    border: none;
    margin-right: 24px;
    position: absolute;
}

.footer-hr{
    border-radius: 1px;
    opacity: 0.15;
    background: #FFF;
    height: 1px;
    width: 96%;
    max-width: 1396px;
    margin-top: 48px;
}

.footer-hr-100{
    border-radius: 1px;
    opacity: 0.15;
    background: #FFF;
    height: 1px;
    width: 100%;
    margin-top: 48px;
}

.apps-list a{
    display: flex;
    padding: 14px 86px 14px 24px;
    align-items: center;
    align-self: stretch;
    border-radius: 16px;
    background: var(--Base-white-100, rgba(255, 255, 255, 0.10));
}

.footer-nav-container{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 96%;
    max-width: 1396px;
    margin-top: 64px;
    ul li{
        text-align: left;
        a{
            text-align: left;
            color: white;
            text-decoration: none;
            font-family: "HelveticaNeue";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0.28px;
        }
    }
}

.mobile-app-btn-text-12{
    color: #FFF;
    leading-trim: both;
    text-edge: cap;
    font-family: "HelveticaNeue";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    margin-bottom: 6px;
}

.mobile-app-btns{
    display: flex;
    flex-direction: column;
}

.mobile-app-btn-text-14{
    color: #FFF;
    leading-trim: both;
    text-edge: cap;
    font-family: "HelveticaNeue";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
}

.footer-nav-column{
    padding: 0;
}

.footer-nav-title{
    font-family: "HelveticaNeue";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    margin-bottom: 16px;
    color: white;
}

.footer-bottom-block{
    flex: 1;
    width: 96%;
    margin: auto;
    max-width: 1396px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p{
        margin: 0;
        padding: 0;
        color: white;
    }
    .faqs-and-terms{
        display: flex;
        align-items: center;
    }
    .footer-divider-circle{
        width: 4px;
        height: 4px;
        margin: 0 10px;
        background: white;
        border-radius: 4px;
    }
    a{
        color: white;
        margin: 0;
        padding: 0;
        text-decoration: none;
    }
}

@media(max-width: 1024px){
    .footer-head{
        flex-direction: column;
        img{
            margin-bottom: 36px;
        }
        .subscribe-news-footer{
            width: 90%;
        }
        .subscribe-news-input{
            width: 100%;
        }
    }
    .footer-nav-container{
        flex-wrap: wrap;
    }
    .footer-container{
        height: fit-content !important;
    }
}