.notification-box {
  padding: 16px;
  border-radius: 6px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 492px;
  position: fixed;
  z-index: 1111;
  bottom: 20px;
  left: 10px;
}

.success-notification {
  background: #EDFBF2;
}

.info-notification {
  background: #D6EFFA;
}

.error-notification {
  background: #FFDAD7;
}

.notification-success-text {
  color: rgba(61, 146, 58, 0.7) !important;
}

.notification-info-text {
  color: #288AB8 !important;
}

.notification-error-text {
  color: #CC372E !important;
}

.notification-text-body {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.notification-body-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}/*# sourceMappingURL=Notification.css.map */