.news-card-container {
  width: 448px;
  height: 406px;
  border-radius: 20px 20px 0px 0px;
  background: linear-gradient(181deg, #000 1.2%, rgba(18, 20, 24, 0) 97.82%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 20px;
  padding: 16px;
  text-decoration: none;
}
.news-card-container .news-tooltip {
  display: inline-flex;
  padding: 10px 15px;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  background: #FFF;
}
.news-card-container .news-tooltip span {
  color: var(--Grayscale-900, #000);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}
.news-card-container .news-card-title {
  color: #FFF;
  font-family: "HelveticaNeue";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  margin-bottom: 12px;
}
.news-card-container .news-card-description {
  color: #FFF;
  opacity: 0.7;
  font-family: "HelveticaNeue";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 22px;
}
.news-card-container .news-card-publish-date-and-time {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.news-card-container .news-card-publish-date-and-time span {
  color: #FFF;
  font-family: "HelveticaNeue";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.7;
}
.news-card-container .news-card-publish-date-and-time svg {
  opacity: 0.7;
  margin: 0 4px 0 23px;
}

.news-card-slider {
  margin-bottom: 184px;
}

.main-page-news-card-list {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.section-title {
  color: var(--Grayscale-900, #000);
  font-family: "HelveticaNeue";
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 50px;
  line-height: normal;
  text-align: left;
}

.news-card-details {
  width: 100%;
}
.news-card-details .news-card-description {
  text-align: left;
}

.blog-container {
  width: 96%;
  margin: auto;
  max-width: 1396px;
}
.blog-container .pager-history {
  margin: 42px 0 32px 0;
  color: var(--Grayscale-600, #666);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
}
.blog-container .pager-history a {
  color: var(--Grayscale-600, #666);
}
.blog-container figure {
  width: 100%;
}
.blog-container figure img {
  width: 100%;
  max-width: 1396px;
}
.blog-container .blog-details-title {
  color: var(--Grayscale-900, #000);
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  text-align: left;
  margin-bottom: 64px;
}

.blogs-switcher {
  padding: 32px 0;
  border-top: 1px solid #E1E1E1;
  border-bottom: 1px solid #E1E1E1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 52px 0 152px 0;
}
.blogs-switcher div {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #666;
}
.blogs-switcher a {
  color: black;
  text-decoration: none;
}

@media (max-width: 1024px) {
  .main-page-news-card-list {
    overflow: auto;
  }
  .main-page-news-card-list::-webkit-scrollbar {
    display: none;
  }
  .main-page-news-card-list .news-card-container {
    width: 292px;
    min-width: 292px;
    height: 302px;
    margin-right: 16px;
  }
  .main-page-news-card-list .news-card-title, .main-page-news-card-list .news-card-description {
    font-size: 10px;
  }
  .main-page-news-card-list .news-card-publish-date-and-time {
    font-size: 12px;
  }
  .main-page-news-card-list .news-card-publish-date-and-time svg {
    margin: 0 4px 0 10px;
  }
  .news-card-slider {
    margin-bottom: 56px;
  }
}/*# sourceMappingURL=NewsCard.css.map */