.auto-auction-banner-container{
    margin-top: 144px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 106px;
    .auto-auction-banner-column-right{
        width: 568px;
    }
    .auto-auction-banner-column-left{
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        width: 740px;
    }
    .auto-auction-banner-title{
        color: var(--Grayscale-900, #000);
        font-family: "HelveticaNeue";
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 0;
        text-align: left;
    }
    .auto-auction-banner-subtitle{
        color: var(--Grayscale-800, #333);
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        margin: 42px 0 64px 0;
    }
    .auto-auction-banner-bullet{
        display: flex;
        align-items: flex-start;
        margin-bottom: 32px;
        img{
            border-radius: 12px;
            background: var(--Background-Primary, rgba(90, 126, 255, 0.10));
            padding: 4px;
            margin-right: 14px;
        }
    }
    .auto-auction-banner-bullet p{
        color: var(--Grayscale-900, #000);
        font-family: "HelveticaNeue";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 0;
        text-align: left;
        margin-bottom: 0;
    }
    .start-using-auction-tool{
        border-radius: 16px;
        background: #405FF2;
        width: 154px;
        height: 56px;
        padding: 16px 32px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        display: flex;
        color: white;
        text-decoration: none;
    }
}

.our-goals-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 42px;
    .our-gound-item{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .our-goal-count{
        color: var(--Grayscale-900, #000);
        text-align: center;
        font-family: "HelveticaNeue";
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 10px;
    }
    .goal-item-name{
        color: #050B20;
        text-align: center;
        font-family: "HelveticaNeue";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        margin-bottom: 0;
    }
}

@media(max-width: 1024px){
    .auto-auction-banner-container{
        flex-direction: column;
        .auto-auction-banner-title{
            margin: 32px 0 24px 0;
            font-size: 16px;
        }
        .auto-auction-banner-column-left{
            width: 100%;
            img{
                width: calc(50% - 8px);
            }
        }
        .auto-auction-banner-subtitle{
            margin: 0;
            padding: 0;
            text-align: left;
            margin-bottom: 33px;
        }
        .auto-auction-banner-column-right{
            width: 100%;
        }
    }
}