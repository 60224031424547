.main-page-transportation-calculator-container{
    width: 100%;
    height: 628px;
    border-radius: 16px;
    background: #050B20;
    margin-bottom: 184px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .main-page-transportation-calculator-banner{
        width: calc(100% - 756px);
        height: 100%;
        background-image: url('https://carspace-app.crg.ge/static/containers.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
    }
    .calculator-content-box{
        flex: 1;
        height: 100%;
        padding: 96px 95px 42px 95px;
        h2{
            color: #FFF;
            font-family: "HelveticaNeue";
            font-size: 33px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-align: left;
            margin-bottom: 42px;
        }
        .main-page-transportation-calculator-subtitle{
            color: #FFF;
            font-family: "HelveticaNeue";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            text-align: left;
            margin-bottom: 50px;
        }
    }
    .main-page-transportation-calculator-form{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .main-page-calculate-button{
        border-radius: 16px;
        background: var(--Gradient-Main-Main, linear-gradient(254deg, #75D4FD -141.51%, #5A7EFF 108.14%));
        display: flex;
        width: 100%;
        height: 64px;
        padding: 16px 32px;
        justify-content: center;
        align-items: center;
        gap: 32px;
        border: none;
        margin-top: 14px;
        p{
            color: white;
            margin-bottom: 0;
            margin-right: 16px;
        }
    }
    .main-page-calculate-button-content{
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.calculator-result-content{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    margin-top: 16px;
}
.calculator-result-title{
    color: var(--Background-Additional, #FFF);
    text-align: center;
    font-size: 14px;
    margin-bottom: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}
.calculator-result-value{
    color: rgba(87, 205, 83, 0.90);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    letter-spacing: 0.32px;
    margin-bottom: 0;
}

@media(max-width: 1024px){
    .main-page-transportation-calculator-container{
        flex-direction: column;
        height: unset;
        .calculator-content-box{
            padding: 42px 48px;
        }
        .main-page-transportation-calculator-banner{
            width: 100%;
            height: 350px;
            border-radius: 20px;
        }
        h2{
            font-size: 16px !important;
            margin-bottom: 24px !important;
        }
        .main-page-transportation-calculator-subtitle{
            font-size: 14px !important;
            margin-bottom: 52px !important;
        }

    }
}

@media(max-width: 760px){
    .main-page-transportation-calculator-container{
        .calculator-dropdown-container{
            width: 100% !important;
        }
        .calculator-result-content{
            flex-direction: column;
            align-items: center;
            text-align: center;
            .calculator-result-value{
                margin-bottom: 24px;
            }
        }

    }
    .section-title{
        font-size: 16px !important;
        margin-bottom: 24px !important;
    }
}