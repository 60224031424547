.transactions-page-filter-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .date-filter-input{
        border-radius: 8px;
        border: 1px solid var(--Grayscale-100, #E6E6E6);
        background: #FFF;
        width: 132px;
        height: 42px;
    }
}