.data-table-container{
    width: 100%;
    thead th{
        text-align: left;
        border-bottom: #405FF2 2px solid;
        padding-bottom: 10px;
    }
    tbody tr td{
        padding: 8px 0px;
        text-align: left;
    }
}

.search-by-keyword-box{
    border: 1px solid rgb(206, 206, 206);
    padding: 0px 15px;
    border-radius: 12px;
    height: 38px;
    width: 270px;
    outline: none;
}

.data-table-filter-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0 40px 0;
}

.data-table-filter-sides{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.search-by-keyword-loop{
    position: absolute;
    padding-right: 10px;
    cursor: pointer;
}

.message-not-read{
    background: rgb(210, 210, 210);
    width: 6px;
    height: 6px;
    border-radius: 50%;
}

.loader-container{
    width: 100%;
    display: flex;
    justify-content: center;
}

/* HTML: <div class="loader"></div> */
/* HTML: <div class="loader"></div> */
.data-loader {
    width: 15px;
    aspect-ratio: 1;
    border-radius: 50%;
    animation: l5 1s infinite linear alternate;
  }
  @keyframes l5 {
      0%  {box-shadow: 20px 0 #000, -20px 0 #0002;background: #000 }
      33% {box-shadow: 20px 0 #000, -20px 0 #0002;background: #0002}
      66% {box-shadow: 20px 0 #0002,-20px 0 #000; background: #0002}
      100%{box-shadow: 20px 0 #0002,-20px 0 #000; background: #000 }
  }