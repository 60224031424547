.loading-page-container{
    background:  #0C111C;
    background-blend-mode: color, normal;
    box-shadow: -4px 4px 12px 8px rgba(0, 0, 0, 0.15);
    width: 100vw;
    height: 100vh;
    padding-top: 24px;
    padding-left: 54px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.loading-page-left-side{
    h2{
        color: white;
        font-size: 64px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: center;
        letter-spacing: 0.17px;
    }
}

.loading-page-right-side{
    margin-top: 100px;
}

/* HTML: <div class="loader"></div> */
.loader {
    width: 40px;
    height: 20px;
    background: orange;
    position: relative;
    animation: l9-0 1.5s infinite linear;
  }
  .loader:before,
  .loader:after {
     content:"";
     position: absolute;
     background: inherit; 
     bottom: 100%;
     width: 50%;
     height: 100%;
     animation: inherit;
     animation-name: l9-1;
  }
  .loader:before {
    left: 0;
    transform-origin: bottom left;
    --s:-1;
  }
  .loader:after {
    right: 0;
    transform-origin: bottom right;
  }
  @keyframes l9-0 {
     0%,10%   {transform:translateY(0%)    scaleY(1)}
     49.99%   {transform:translateY(-50%)  scaleY(1)}
     50%      {transform:translateY(-50%)  scaleY(-1)}
     90%,100% {transform:translateY(-100%) scaleY(-1)}
  }
  @keyframes l9-1 {
     10%,90% {transform: rotate(0deg)}
     50%     {transform: rotate(calc(var(--s,1)*180deg))}
  }